<template>
  <div class="user">
    <back></back>
    <div class="avatar">
      <div class="avatar-wrap">
        <van-uploader :after-read="getFile" max-count="1">
          <img :src="form.avatar !== '0' ? form.avatar : require('../assets/img/user.png')" class="avatar-icon">
          <span class="camera">
          <img :src="require('../assets/img/camera.png')"/>
        </span>
        </van-uploader>
      </div>
    </div>
    <van-form @submit="onSubmit" label-width="5em"
              @failed="fail" validate-first>
      <div class="form">
        <van-field
          v-model="form.username"
          name="昵称"
          label="昵称"
          placeholder="请填写昵称"
          :maxlength="20"
          :rules="[{ validator, message: '只能含有汉字、数字、字母、下划线，下划线位置不限' }]"/>
        <van-field
          v-model="form.sex"
          name="性别"
          label="性别">
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal">
              <van-radio name="1" checked-color="black">男</van-radio>
              <van-radio name="2" checked-color="black">女</van-radio>
              <van-radio name="3" checked-color="black">保密</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.tel"
          name="手机号"
          label="手机号"
          :rules="[{ required: true}]">
          <template #input>
            <div style="display: flex;justify-content: space-between;align-items: center;width: 100%;">
              <span class="grey">{{form.tel}}</span>
              <span @click="toPage('changeTel')">修改</span>
            </div>
          </template>
        </van-field>
        <van-field
          v-model="form.idCard"
          name="身份证"
          label="身份证"
          :disabled="true"
          :rules="[{ required: true}]">
          <template #input>
            <div style="display: flex;justify-content: space-between;align-items: center;width: 100%;">
              <span class="grey">{{form.idCard}}</span>
              <span v-if="user.isAttested"><van-icon name="checked" class="green" style="padding-right: 3px;"/>已认证</span>
              <span @click="toPage('attest')" v-else>去认证</span>
            </div>
          </template>
        </van-field>
        <van-field
          v-model="form.baZi"
          name="完善生日"
          label="完善生日"
          :disabled="true"
          :rules="[{ required: true, message: '生日信息必填'}]">
          <template #input>
            <div style="display: flex;justify-content: space-between;align-items: center;width: 100%;">
              <span class="grey">{{form.baZi}}</span>
              <span v-if="user.baZi"><van-icon name="checked" class="green" style="padding-right: 3px;"/>已完善</span>
              <span @click="isShow = true" v-else>修改</span>
            </div>
          </template>
        </van-field>
      </div>
      <div class="down">
        <van-button class="btn f-17"
                    :loading="isLoading"
                    native-type="submit" block>
          <span class="f-17">保存</span>
        </van-button>
        <div class="f-10 text-center m-t-20">您的生日信息仅可修改一次，请谨慎修改</div>
        <div class="f-10 text-center m-t-5">
          您的生日信息将用于藏品铸造，
        </div>
        <div class="f-10 text-center m-t-5">
          请精确到「小时」，但不需要精确到「分钟」。
        </div>
      </div>
    </van-form>
    <van-popup v-model="isShow" position="bottom" :style="{ height: '50%' }"
               :safe-area-inset-bottom="true">
      <van-picker show-toolbar title="生辰八字" :columns="columns"
                  @cancel="isShow = false"
                  @change="onChange" @confirm="confirmDate"/>
    </van-popup>
  </div>
</template>

<script>
import { getQiNiuApi, saveInfoApi, userApi } from '../api/user'
import { mapState, mapMutations } from 'vuex'
import axios from 'axios'
import Back from '../components/Back'
import { getWxConfig } from '../../tool'
const moment = require('moment')

export default {
  name: 'User',
  components: { Back },
  data () {
    return {
      form: {
        username: '',
        sex: '3', // 1.男 2.女 3.保密 4.未认证
        tel: '',
        avatar: '',
        idCard: '',
        baZi: '',
      },
      cloneForm: {},
      domain: '',
      host: '',
      token: '',
      qiuData: new FormData(),
      cloneAvatar: '',
      isLoading: false,
      pattern: /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/g,
      baZiStamp: '',
      isShow: false,
      columns: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  created () {
    this.getQiu()
    this.form.username = this.user.nickname
    this.form.tel = this.user.mobile.slice(0, 3) + '****' + this.user.mobile.slice(7, 11)
    this.form.avatar = this.user.avatar
    this.user.sex === '4' ? this.form.sex = '3' : this.form.sex = this.user.sex.toString()
    this.form.idCard = this.user.idCard
    this.form.baZi = this.user.baZi ? moment(this.user.baZi).format('YYYY-MM-DD HH:mm') : moment(new Date().getTime()).format('YYYY-MM-DD HH:mm')
    this.baZiStamp = this.user.baZi ? this.user.baZi : new Date().getTime()
    this.getAllDate()
    this.cloneForm = JSON.parse(JSON.stringify(this.form))
    getWxConfig('轻松小镇', '修改用户资料')
  },
  methods: {
    ...mapMutations({
      vxSetUser: 'setUser'
    }),
    validator (val) {
      return /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(val)
    },
    onSubmit () {
      if (this.isObjectValueEqual(this.form, this.cloneForm)) {
        this.$toast('信息未修改')
        return false
      }
      if (this.form.baZi !== this.cloneForm.baZi) {
        this.$dialog.confirm({
          title: '完善生日',
          message: '生日信息确认后不可修改，请核验信息无误后进行后续操作',
          confirmButtonColor: 'black'
        }).then(() => {
          this.confirmChange()
        }).catch(() =>{})
      } else {
        this.confirmChange()
      }
    },
    isObjectValueEqual(object1, object2) {
      var o1keys = Object.keys(object1);
      var o2keys = Object.keys(object2);
      if (o2keys.length !== o1keys.length) return false
      for (let i = 0; i <= o1keys.length - 1; i++) {
        let key = o1keys[i];
        if (!o2keys.includes(key)) return false
        if (object2[key] !== object1[key]) return false
      }
      return true
    },
    async confirmChange () {
      this.isLoading = true
      try {
        const success = await saveInfoApi({
          username: this.form.username,
          avator: this.form.avatar,
          sex: this.form.sex,
          bazi: this.baZiStamp
        })
        this.isLoading = false
        this.$toast(success.data.info)
        this.cloneForm = JSON.parse(JSON.stringify(this.form))
        this.getUser()
      } catch (e) {
        console.log(e)
        this.isLoading = false
        this.$toast.fail(e.data)
      }
    },
    fail () {
      this.$toast('请完善所有信息')
    },
    getFile (file, detail) {
      if (file.file.type === 'image/jpeg' || file.file.type === 'image/png') {
        this.$toast({
          type: 'loading',
          message: '图片上传中...'
        })
        // https://up-z2.qiniup.com
        this.qiuData.append('file', file.file)
        this.qiuData.append('key', new Date().getTime() + file.file.name)
        axios({
          url: this.host,
          data: this.qiuData,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(async (res) => {
          console.log(res)
          this.form.avatar = this.domain + res.data.key
        }).catch(e => {
          this.isLoading = false
          console.log(e)
          this.$toast('上传图片失败')
        })
      } else {
        this.$toast.fail('请上传.jpg或.png格式图片')
      }
    },
    async getQiu () {
      try {
        const res = await getQiNiuApi({})
        this.domain = res.data.qiniu.qiniuconfig.domain
        this.host = res.data.qiniu.qiniuconfig.host
        this.token = res.data.qiniu.qiniuconfig.token
        this.qiuData.append('token', this.token)
        console.log(res)
      } catch (e) {
        console.log(e)
      }
    },
    async getUser () {
      try {
        const res = await userApi({})
        this.vxSetUser({
          day: res.data.day,
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user
        })
      } catch (e) {
        console.log(e)
        if (e.code === 201) {
          this.isShowLogin = true
        }
      }
    },
    toPage (name) {
      this.$router.push({
        name
      })
    },
    confirmDate (value) {
      const string = `${value[0]}/${value[1].replace('月', '')}/${value[2].replace('日', '')} ${value[3].replace('时', '')}:${value[4].replace('分', '')}`
      this.baZiStamp = new Date(string).getTime()
      this.form.baZi = moment(this.baZiStamp).format('YYYY-MM-DD HH:mm')
      this.isShow = false
    },
    getAllDate () {
      const year = new Date().getFullYear()
      let yearArray = []
      let monthArray = []
      let dayArray = []
      let hourArray = []
      let minArray = []
      for (let i = year; i > year - 100; i--) {
        yearArray.push(i)
      }
      for (let j = 1; j < 13; j++) {
        monthArray.push(j + '月')
      }
      for (let m = 1; m < 32; m++) {
        dayArray.push(m + '日')
      }
      for (let n = 0; n < 24; n++) {
        hourArray.push(n + '时')
      }
      for (let k = 0; k < 60; k++) {
        minArray.push(k + '分')
      }
      this.columns = [
        {
          values: yearArray,
          defaultIndex: 0,
        },
        {
          values: monthArray,
          defaultIndex: 0,
        },
        {
          values: dayArray,
          defaultIndex: 0,
        },
        {
          values: hourArray,
          defaultIndex: 0,
        },
        {
          values: minArray,
          defaultIndex: 0,
        }
      ]
    },
    onChange(picker, values) {
      const time = new Date(values[0], values[1].replace('月',''), 0)
      const number = time.getDate()
      const days = []
      for (let i = 1; i < number + 1; i++) {
        days.push(i + '日')
      }
      picker.setColumnValues(2, days)
    }
  }
}
</script>

<style scoped lang="scss">
.user{
  min-height: 100vh;
  background: url("../assets/img/user-bg.png") no-repeat;
  background-size: 100%;
  padding-top: 45px;
  .avatar{
    .avatar-wrap{
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin: 0 auto;
      position: relative;
      .avatar-icon{
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
      .camera{
        position: absolute;
        right: -1px;
        bottom: 9px;
        width: 19px;
        height: 19px;
        background: #9CA6E9;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
         width: 12px;
          height: 12px;
        }
      }
    }
  }
  .form{
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 50px 40px;
    background: url("../assets/img/user-bg2.png") no-repeat;
    background-size: 100% 100%;
    /deep/ .van-cell{
      padding: 0;
      background-color: transparent;
      margin-bottom: 30px;
      align-items: center;
      &:after{
        display: none;
      }
      .van-field__label,.van-field__control{
        color: black;
      }
      .van-field__control{
        color: black;
        font-weight: 500;
      }

      .van-field__value {
        background: #FFFFFF;
        border-radius: 18px;
        padding: 5px 15px;
      }
      &:nth-child(3), &:nth-child(4),&:nth-child(5) {
        .van-field__value {
          background: transparent;
        }
      }
    }
  }
  .down {
    padding: 0 50px 10px;
    .btn{
      padding: 15px;
      font-weight: 500;
      border-radius: 30px;
      text-align: center;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #FFFFFF;
      box-shadow: 1px 8px 32px 1px rgba(218, 230, 250, 0.65);
    }
  }
}
</style>
